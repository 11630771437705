export default {
  siteMetadata: {
    title: "Free Online Games for PC | Free Web Browser Games - Epicplay.in",
    description:
      "Epicplay.in is the best place to play free online games for PC. We have a huge selection of free web browser games to play on computer. Start playing today!",
    siteUrl: "https://www.epicplay.in",
    image: "https://www.epicplay.in/epicplay-og.webp",
    logo: "https://www.epicplay.in/epicplay-logo.jpg",
    social: {
      instagram: "https://www.instagram.com/epicplay.in/",
      discord: "https://discord.com/invite/pntAKRxaeG",
      facebook: "https://www.facebook.com/Epic-Play-100534342864317",
      youtube: "https://youtube.com/@epicplay_in",
      twitter: "https://twitter.com/epicplay_in"
    },
  },
};
