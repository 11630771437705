import Axios from "axios";
import { epToast } from "./epToast";

export async function getJsonFromUrl(url) {
  try {
    const json = await Axios.get(url, {
      headers: { "content-type": "application/json" },
    });
    return json.data;
  } catch (err) {
    console.log(err);
  }
}

export function getClientPageUrl() {
  const hostname =
    typeof window !== "undefined" && window.location.hostname
      ? window.location.hostname
      : "";
  const pathname =
    typeof window !== "undefined" && window.location.pathname
      ? window.location.pathname
      : "";

  return hostname + pathname;
}
export function makeMap(arr, keyFinder, formator) {
  return arr.reduce((obj, c, idx, arr) => {
    obj[keyFinder(c, idx, arr)] = formator ? formator(c) : c;
    return obj;
  }, {});
}

export const withDefaultUIHandler = async (
  fn,
  successMsg = false,
  errorMsg = true,
  propagateError = true
) => {
  try {
    const data = await (fn.then ? fn : fn());
    if (successMsg) {
      //@todo
    }
    return data;
  } catch (error) {
    if (errorMsg !== false) {
      console.error(errorMsg === true ? formatServerErrors(error) : error);
      epToast.error(
        {
          title: errorMsg === true ? formatServerErrors(error) : error,
        },
        {
          id: JSON.stringify(error),
        }
      );
    }
    if (propagateError) {
      throw error;
    }
  }
};

export function formatServerErrors(error) {
  const serverErrorMsg =
    error?.response?.data?.error || error?.response?.data?.err;

  if (serverErrorMsg && typeof serverErrorMsg === "string") {
    return [serverErrorMsg];
  }

  if (error?.response) {
    const responseData = error.response.data;
    const statusCode = error.response.status;

    if (statusCode === 503 || statusCode === 502) {
      return ["Service down. Please try again."];
    }
    if ([413].includes(statusCode)) {
      return [`The file you are trying to upload is too large`];
    }
    if (responseData && typeof responseData === "string") {
      return [responseData];
    }
    if ([404, 400, 500, 501, 405].includes(statusCode)) {
      return ["Some error occurred, please try again"];
    }

    if ([403, 401].includes(statusCode)) {
      return [`Forbidden. You're not allowed to perform the action`];
    }

    if (typeof responseData === "string" && responseData.includes("Network")) {
      //"Network Error caught"
      if (window.navigator.onLine) {
        return ["Server Error"];
      }
      return ["Network Error or Service down"];
    }

    return ["Some error occurred, please try again"];
  }

  if (error.message && typeof error.message === "string") {
    return !window.navigator.onLine
      ? ["No active internet connection!"]
      : [error.message];
  }

  return ["Some error occurred, please try again"];
}

export function tryToParse(data) {
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
}

export async function initRecaptcha() {
  console.log("initRecaptcha");
  const isLoaded = await loadScript(GOOGLE_RECAPTCHA_BASE_URL + RECAPTCHA_KEY);
  if (isLoaded) {
    window.grecaptcha.enterprise.ready(() => {});
  } else {
    console.log("Recaptcha failed to load");
  }
}

export function isShareSupported() {
  return (
    typeof window !== "undefined" &&
    window &&
    window.navigator &&
    window.navigator.share
  );
}

export function isCopySupported() {
  return (
    typeof window !== "undefined" &&
    window !== undefined &&
    window.navigator &&
    window.navigator.clipboard
  );
}

export function tryToShare(
  data = {
    title: "title",
    description: "description",
    link: "link",
    files: null,
  }
) {

  if (isShareSupported()) {
    const payload = {
      title: data?.title,
      text: data?.description,
      url: data?.link,
    };
    if (data.files) {
      payload.files = [data.files];
    }

    window.navigator
      .share(payload)
      .then(() => {
        epToast.success(
          {
            title: "Link Shared!",
            description: data?.link,
          },
          {
            id: "link-shared",
          }
        );
      })
      .catch(() => {});
  } else if (isCopySupported()) {
    navigator.clipboard.writeText(data?.link);
    epToast.success(
      {
        title: "Link Copied!",
        description: `${data?.link}`,
      },
      {
        id: "copied",
      }
    );
  } else {
    epToast.error(
      {
        title: "Share not supported",
        description: "Please copy the link",
      },
      {
        id: "copied",
      }
    );
  }
}
export function shareImage(
  data = {
    title: "title",
    description: "description",
    link: "link",
    files: null,
  }
) {
  const isShareSupported =
    typeof window !== "undefined" &&
    window &&
    window.navigator &&
    window.navigator.share;
  const isCopySupported =
    typeof window !== "undefined" &&
    window !== undefined &&
    window.navigator &&
    window.navigator.clipboard;

  if (isShareSupported) {
    const payload = {
      title: data?.title,
      text: data?.description,
      url: data?.link,
    };
    if (data.files) {
      payload.files = [data.files];
    }
    window.navigator
      .share(payload)
      .then(() => {
        epToast.success(
          {
            title: "Image Shared!",
            description: data?.text,
          },
          {
            id: "image-shared",
          }
        );
      })
      .catch(() => {});
  } else if (isCopySupported) {
    navigator.clipboard.writeText(data?.link);
    epToast.success(
      {
        title: "Image Copied!",
        description: `${data?.link}`,
      },
      {
        id: "copied",
      }
    );
  } else {
    epToast.error(
      {
        title: "Share not supported",
        description: "Please copy the link",
      },
      {
        id: "copied",
      }
    );
  }
}

export const validateUsername = username => {
  if (!username) {
    epToast.error("Name cannot be empty", {
      id: "change-user-name-error",
    });
    return false;
  }
  if (username.length < 3 || username.length > 16) {
    epToast.error("Name must be between 3 and 16 characters", {
      id: "change-user-name-error",
    });
    return false;
  }
  // username must only contains alphanumeric, hyphens, dashes and underscores
  if (!/^[a-zA-Z0-9_-]+$/.test(username)) {
    epToast.error(
      "Name must only contains alphanumeric, hyphens, dashes and underscores",
      { id: "change-user-name-error" }
    );
    return false;
  }
  return true;
};
export function downloadLink(url, filename = "EpicPlay.apk") {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
}
