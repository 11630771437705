import toast from "react-hot-toast";

export const epToast = {
  success(message, options) {
    const title = message?.title || message;
    const description = message?.description || null;
    toast.success(
      <div>
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {title}
        </div>
        {description && <div>{description}</div>}
      </div>,
      {
        ...options,
      }
    );
  },
  error(message, options) {
    const title = message?.title || message;
    const description = message?.description || null;
    toast.error(
      <div>
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {title}
        </div>
        {description && <div>{description}</div>}
      </div>,
      {
        ...options,
      }
    );
  },
  info(message, options) {
    const title = message?.title || message;
    const description = message?.description || null;
    toast(
      <div>
        <div
          style={{
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {title}
        </div>
        {description && <div>{description}</div>}
      </div>,
      {
        ...options,
      }
    );
  },
};
